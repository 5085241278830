import React from "react";
import { Link } from "react-router-dom";
import firebase from "firebase";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";

const Input = ({ accept, onFiles, files, getFilesFromEvent }) => {
  return (
    <label
      class="text-center"
      style={{ cursor: "pointer", width: "100%", height: "100%" }}
    >
      <p>
        <svg
          class="mx-auto h-12 w-12 text-gray-400"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 48 48"
        >
          <path
            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p class="mt-1 text-sm text-gray-600">
          <span class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition duration-150 ease-in-out pr-1">
            Upload a file
          </span>
          or drag and drop
        </p>
        <p class="mt-1 text-xs text-gray-500">PNG, JPG, GIF up to 20MB</p>
      </p>
      <input
        style={{ display: "none" }}
        type="file"
        accept={accept}
        onChange={async (e) => {
          const target = e.target;
          const chosenFiles = await getFilesFromEvent(e);
          onFiles(chosenFiles);
          //@ts-ignore
          target.value = null;
        }}
      />
    </label>
  );
};

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      cards: {},
      cardsKeys: [],
      name: "",
      desc: "",
      value: "",
      file: "",
      total: "",
      editing: false,
      editInd: null,
    };
  }

  SingleUpload = (file) => {
    const toast = (innerHTML) => {
      try {
        const el = document.getElementById("toast");
        el.innerHTML = innerHTML;
      } catch {}
    };

    const getUploadParams = async () => {
      let idToken = await getID();
      return {
        url: "https://api.peshnhs.org/uploadFiles",
        headers: {
          idtoken: idToken,
        },
      };
    };

    const getID = async () => {
      return new Promise((resolve, reject) => {
        firebase
          .auth()
          .currentUser.getIdToken(false)
          .then((idToken, err) => {
            return resolve(idToken);
          })
          .catch((error) => {
            alert("Activation error. Try signing out and signing in again.");
            reject(error);
          });
      });
    };

    const handleChangeStatus = ({ meta, remove, xhr }, status) => {
      console.log(status);
      if (status === "done") {
        if (xhr != undefined && xhr.responseText) {
          let parsed = JSON.parse(xhr.response);
          if (parsed.success == 1) {
            toast(
              `${meta.name} uploaded! <img style="object-fit: contain;" src="https://media.peshnhs.org/media/${parsed.file}" />`
            );
            remove();
            this.setState({
              file: parsed.file,
            });
          } else {
            toast(`${meta.name} failed! Check filesize and filetype!`);
            remove();
          }
        } else {
          toast(`${meta.name} failed! Check filesize and filetype!`);
          remove();
        }
      } else if (status === "aborted") {
        toast(`${meta.name}, upload failed...`);
      }
    };
    const handleStart = () => {
      if (file != null) {
        setTimeout(
          () =>
            toast(
              `Attached file: <img style="object-fit: contain;" src="https://media.peshnhs.org/media/${file}" />`
            ),
          100
        );
      }
    };
    return (
      <React.Fragment>
        <div id="toast" class="mt-0 text-sm text-gray-500"></div>
        <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          maxFiles={1}
          maxSizeBytes={20971520}
          multiple={false}
          canCancel={false}
          inputContent="Drop A File"
          tex
          styles={{
            dropzoneActive: { borderColor: "green" },
          }}
          classNames={{
            dropzone:
              "mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md",
          }}
          InputComponent={Input}
        />
        {handleStart()}
      </React.Fragment>
    );
  };

  componentDidMount = () => {
    firebase
      .database()
      .ref("cards/" + firebase.auth().currentUser.email.replace(/\./g, ','))
      .on("value", async (snapshot) => {
        if (snapshot.val() != null) {
          var total = await this.getTotal(snapshot.val());
          this.setState({
            cards: snapshot.val(),
            cardsKeys: Object.keys(snapshot.val()),
            total: total,
          });
        } else {
          this.setState({ cards: {}, cardsKeys: [], total: 0 });
        }
      });
    // firebase
    // .database()
    // .ref("credits/" + firebase.auth().currentUser.email.replace(/\./g, ','))
    // .set([{
    //     name: "TESTING",
    //     desc: "Hello",
    //     value: 3
    // }]);
  };

  getTotal = async (val) => {
    return new Promise((resolve, reject) => {
      var tempKeys = Object.keys(val);
      if (tempKeys.length == 0) return 0;
      var total = [];
      tempKeys.map((value, index) => {
        total.push(parseFloat(val[value].value));
        console.log(total);
        if (tempKeys.length == total.length) {
          return resolve(total.reduce((a, b) => a + b, 0));
        }
      });
    });
  };

  renderTable = () => {
    if (this.state.cardsKeys.length > 0) {
      return (
        <>
          {this.state.cardsKeys.map((value, index) => (
            <tr class={this.state.cards[value].verify != undefined ? (this.state.cards[value].verify ? "bg-green-50" : "bg-red-50") : "bg-yellow-50"}>
              <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">
                  <a
                    href={
                      "https://media.peshnhs.org/media/" +
                      this.state.cards[value].file
                    }
                    target="_blank"
                    class="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                  >
                    Uploaded Photo
                  </a>
              </td>
              
              <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500"></td>
              <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500"></td>
              <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500"></td>
              <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                {this.state.cards[value].verify ? (
                  <span class="text-indigo-600 hover:text-indigo-900 focus:outline-none">
                    Verified
                  </span>
                ) : (
                  <a
                    href="#"
                    onClick={() => this.startEdit(value)}
                    class="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                  >
                    Edit
                  </a>
                )}
              </td>
            </tr>
          ))}
        </>
      );
    }
  };

  startEdit = (index) => {
    this.setState({
      editing: true,
      editInd: index,

      file: this.state.cards[index].file,
    });
  };

  startModal = (index) => {
    this.setState({
      modal: true,
      name: "",
      value: "",
      desc: "",
      file: "",
    });
  };

  handleSubmitModal = (e) => {
    e.preventDefault();
    if (this.state.file == "") {
      alert("Please upload a photo for proof before submitting.");
    } else {
      firebase
        .database()
        .ref("cards/" + firebase.auth().currentUser.email.replace(/\./g, ','))
        .push({
          file: this.state.file,
        })
        .then(() => {
          this.setState({
            modal: false,
            file: "",
          });
        });
    }
  };

  handleEditModal = (e) => {
    e.preventDefault();
    firebase
      .database()
      .ref(`cards/${firebase.auth().currentUser.email.replace(/\./g, ',')}/${this.state.editInd}`)
      .set({
        name: this.state.name,
        value: this.state.value,
        desc: this.state.desc,
        file: this.state.file,
        verify: null
      })
      .then(() => {
        this.setState({
          editing: false,
          name: "",
          desc: "",
          value: "",
          file: this.state.file,
        });
      });
  };

  handleDeleteModal = () => {
    firebase
      .database()
      .ref(`cards/${firebase.auth().currentUser.email.replace(/\./g, ',')}/${this.state.editInd}`)
      .remove()
      .then(() => {
        this.setState({
          editing: false,
          name: "",
          desc: "",
          value: "",
          file: this.state.file,
        });
      });
  };

  handleChanges = (e) => {
    this.setState({ [e.target.id]: e.target.value }, () => {});
  };

  fileChangedHandler = (e) => {
    this.setState({ selectedFile: e.target.files[0] });
  };

  renderModal = () => {
    return (
      <form onSubmit={this.handleSubmitModal}>
        <div class="fixed bottom-0 top-0 left-0 px-4 py-4 inset-x-0 sm:flex sm:items-center sm:justify-center overflow-y-auto">
          <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <div class="relative bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6">
            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button
                onClick={() => this.setState({ modal: false })}
                type="button"
                class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
              >
                <svg
                  class="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="folder-add w-6 h-6 text-blue-600"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 4a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V8a2 2 0 00-2-2h-5L9 4H4zm7 5a1 1 0 00-2 0v1H8a1 1 0 000 2h1v1a1 1 0 002 0v-1h1a1 1 0 000-2h-1V9z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Submit Card
                </h3>
                <div class="mt-2">
                  <p class="text-sm leading-5 text-gray-500">
                    Please fill the form below and attach photo of card.
                  </p>
                </div>
              </div>
            </div>
            <>
              <div class="grid grid-cols-6 gap-3 pt-4">
                <div class="col-span-6 sm:col-span-6">
                  <label
                    for="cover_photo"
                    class="block text-sm leading-5 font-medium text-gray-700"
                  >
                    Photo of Card
                  </label>

                  {this.SingleUpload(null)}
                </div>
              </div>
            </>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <button
                  type="submit"
                  class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Submit
                </button>
              </span>
              <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                <button
                  onClick={() => this.setState({ modal: false })}
                  type="button"
                  class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Cancel
                </button>
              </span>
            </div>
          </div>
        </div>
      </form>
    );
  };

  renderEdit = () => {
    return (
      <form onSubmit={this.handleEditModal}>
        <div class="fixed bottom-0 top-0 left-0 px-4 py-4 inset-x-0 sm:flex sm:items-center sm:justify-center overflow-y-auto">
          <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <div class="relative bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6">
            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button
                onClick={() => this.setState({ modal: false })}
                type="button"
                class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
              >
                <svg
                  class="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="folder-add w-6 h-6 text-blue-600"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 4a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V8a2 2 0 00-2-2h-5L9 4H4zm7 5a1 1 0 00-2 0v1H8a1 1 0 000 2h1v1a1 1 0 002 0v-1h1a1 1 0 000-2h-1V9z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Submit Card
                </h3>
                <div class="mt-2">
                  <p class="text-sm leading-5 text-gray-500">
                    Please fill the form below and attach photo of card. 
                  </p>
                </div>
              </div>
            </div>
            <>
              <div class="grid grid-cols-6 gap-3 pt-4">
                <div class="col-span-6 sm:col-span-6">
                  <label
                    for="cover_photo"
                    class="block text-sm leading-5 font-medium text-gray-700"
                  >
                    Photo of Card
                  </label>

                  {this.SingleUpload(this.state.file)}
                </div>
              </div>
            </>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <button
                  type="submit"
                  class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Submit
                </button>
              </span>
              <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <button
                  onClick={() =>
                    this.setState({
                      editing: false,
                      name: "",
                      desc: "",
                      value: "",
                      file: "",
                    })
                  }
                  type="button"
                  class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Cancel
                </button>
              </span>
              <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <button
                  onClick={this.handleDeleteModal}
                  type="button"
                  class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-500 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Delete
                </button>
              </span>
            </div>
          </div>
        </div>
      </form>
    );
  };

  render() {
    return (
      <main
        className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6"
        tabindex="0"
        x-data
        x-init="$el.focus()"
      >
        {this.state.modal && this.renderModal()}
        {this.state.editing && this.renderEdit()}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">Cards for Patients 2022</h1>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="py-4">
            <div class="flex flex-col">
              <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                  <table class="min-w-full">
                    <thead>
                      <tr>
                        <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Card Photo
                        </th>
                        <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                        <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                        <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                        <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>

                      </tr>
                    </thead>
                    <tbody class="bg-white">
                      {/* <tr>
                        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                          NTXFB
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                          Volunteered at Food Bank for 1 hour.
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                          <a
                            href="#"
                            class="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                          >
                            Uploaded Photo
                          </a>
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                          1
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                          <a
                            href="#"
                            class="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                          >
                            Edit
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                          #TTT
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                          Talk to Teachers Challenge
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                          <a
                            href="#"
                            class="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                          >
                            <svg
                              className="h-5 w-5 text-indigo-600 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
                              style={{ display: "inline-flex" }}
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 24 24 "
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                              ></path>
                            </svg>
                            Tracked through peshnhs.org
                          </a>
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                          2
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                          <a
                            href="#"
                            class="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                          >
                            Edit
                          </a>
                        </td>
                      </tr> */}
                      {this.renderTable()}
                    
                      </tbody>
                    </table>
                    <div class="mt-0 py-2 px-2 bg-white">
                      <div class="flex justify-end">
                        <span class="inline-flex rounded-md shadow-sm">
                          <a
                            href="mailto:peshnhs@gmail.com"
                            class="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
                          >
                            Dispute / Help
                          </a>
                        </span>
                        <span class="ml-3 inline-flex rounded-md shadow-sm">
                          <button
                            onClick={() => this.setState({ modal: true })}
                            type="submit"
                            class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                          >
                            Submit Additional Cards
                          </button>
                        </span>
                      </div>
                    </div>
                  {/*</div>
                      {this.renderTable()}
                      <tr>
                        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                          <b>Total</b>
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500"></td>
                        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500"></td>
                        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                          {this.state.total}
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium"></td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="mt-0 py-2 px-2 bg-white">
                    <div class="flex justify-end">
                      <span class="inline-flex rounded-md shadow-sm">
                        <a
                          href="mailto:peshnhs@gmail.com"
                          class="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
                        >
                          Dispute / Help
                        </a>
                      </span>
                      <span class="ml-3 inline-flex rounded-md shadow-sm">
                        <button
                          onClick={() => alert("Please submit new service on the spring credit log.")}
                          type="submit"
                          class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                        >
                          Submissions Closed
                        </button>
                      </span>
                    </div>
                  </div>*/}
                </div>
              </div>
              {/* <div class="bg-white shadow sm:rounded-lg mt-4">
                <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mt-4">
                  <svg
                    class="h-6 w-6 text-green-600"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Service Projects
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm leading-5 text-gray-500 px-4">
                      Just completed a service project? Click the button below
                      to calculate and add the correct amount of credits to your
                      credit log.
                    </p>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6 mb-4">
                  <span class="flex w-full rounded-md shadow-sm justify-center">
                    <button
                      type="button"
                      onClick={this.submitAttendance}
                      class="inline-flex mx-6 justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                      Refresh Padlet Project
                    </button>
                  </span>
                </div>
              </div> */}
              <div class="bg-white shadow sm:rounded-lg mt-4">
                <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 mt-4">
                  <svg
                    class="h-6 w-6 text-yellow-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >t
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Events and Service Projects
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm leading-5 text-gray-500 px-4">
                      Please do not manually add credits for your cards to the creditlog. We will add these to your
                      account automatically.
                    </p>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6 mb-4">
                  <span class="flex w-full rounded-md shadow-sm"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
