import React from "react";
import { Link } from "react-router-dom";
import firebase from "firebase";
import QRCode from "react-qr-code";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  refresh = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {})
      .catch(function (error) {
        alert("Activation error. Try signing out and signing in again.");
      });
  };

  componentDidMount = () => {
    var parent = this;
    this.setState({ loading: true });
    // e.preventDefault();
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        fetch(
          "https://api.peshnhs.org/activateAccount?email=" +
            firebase.auth().currentUser.email,
          {
            headers: {
              idtoken: idToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              this.setState({
                code: "",
                loading: false,
              });
            } else {
              this.setState({ loading: false });
              // alert("Account not activated.");
            }
          })
          .catch((error) => {
            alert("Activation error. Try signing out and signing in again.");
            console.log(error);
          });
      })
      .catch(function (error) {
        alert("Activation error. Try signing out and signing in again.");
        console.log(error);
      });
  };


  // handleSubmit = (e) => {

  // }
  
  handleChanges = (e) => {
    console.log(e.target.value, e.target.checked, e.target.type);
    this.setState({ [e.target.id]: e.target.value }, () => {
      //this.props.firebase.database().ref('submissions/officer/').child(this.props.firebase.auth().currentUser.uid).set({...this.state});
    });
  };

  render() {
    return (
      <main
        className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6 h-full"
        tabindex="0"
        x-data
        x-init="$el.focus()"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">Activation</h1>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="py-4">
            <div class="flex flex-col">
              {/* <div class="bg-white shadow sm:rounded-lg mt-4">
                <div class="px-4 py-5 sm:p-6">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Attendance
                  </h3>
                  <div class="mt-2 max-w-xl text-sm leading-5 text-gray-500">
                    <p>Please click "Check In" below.</p>
                  </div>
                  <div class="mt-5 flex items-center">
                    <div class="mt-5 sm:mt-6">
                      <span class="flex w-full rounded-md shadow-sm">
                        <button
                          type="button"
                          class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        >
                          Go back to dashboard
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div> */}

              <div class="bg-white shadow sm:rounded-lg mt-4">
                <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mt-4">
                  <svg
                    class="h-6 w-6 text-green-600"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div class="mt-3 text-center flex flex-col py-4 justify-center items-center sm:mt-5">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Activate your account
                  </h3>
                  <h4 class="block text-sm font-normal leading-5 pb-2 text-gray-700">
                    Please refresh your screen if you believe you have been activated!
                  </h4>
                  <div class="mt-2">
                    {/* <form onSubmit={this.handleSubmit}>
                      <label
                          for="first_name"
                          class="block text-sm font-normal leading-5 pb-2 text-gray-700"
                        >
                      Your account requires activation. If you have already paid and registered for the 2024-2025 school year, wait until August 28th for automatic activation.
                        </label>
                        <span class="flex w-full rounded-md shadow-sm py-2 ">
                          <button
                            type="submit"
                            class="inline-flex mx-6 justify-center rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                          >
                            Submit
                          </button>
                        </span>
                      </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
