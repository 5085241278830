import React from "react";
import { Link } from "react-router-dom";
import firebase from "firebase";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <main
        className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6 h-full"
        tabindex="0"
        x-data
        x-init="$el.focus()"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="py-4">
            <div class="flex flex-col">
              {/* <div class="bg-white shadow sm:rounded-lg mt-4">
                <div class="px-4 py-5 sm:p-6">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Attendance
                  </h3>
                  <div class="mt-2 max-w-xl text-sm leading-5 text-gray-500">
                    <p>Please click "Check In" below.</p>
                  </div>
                  <div class="mt-5 flex items-center">
                    <div class="mt-5 sm:mt-6">
                      <span class="flex w-full rounded-md shadow-sm">
                        <button
                          type="button"
                          class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        >
                          Go back to dashboard
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div> */}

             

              <div class="bg-white shadow sm:rounded-lg mt-4">
                <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mt-4">
                  <svg
                    class="h-6 w-6 text-green-600"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    You're all set!
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm leading-5 text-gray-500 px-4">
                      Your account has been activated and your registration has
                      been filed. You can access your profile information on the
                      sidebar. 
                    </p>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6 mb-4">
                  <span class="flex w-full rounded-md shadow-sm"></span>
                </div>
              </div>
              {/* <div class="bg-white shadow sm:rounded-lg mt-4">
                <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 mt-4">
                  <svg
                    class="h-6 w-6 text-blue-600"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Orientation 2021-2022
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm leading-5 text-gray-500 px-4 pb-6">
                      Looking for the PESH NHS Orientation 2021-2022 event?
                      Access it through the sidebar or at
                      portal.peshnhs.org/live.
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </main>
    );
  }
}
