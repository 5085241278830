import React from "react";
import { Link } from "react-router-dom";
import firebase from "firebase";
import QrReader from "react-qr-reader";

let audio = new Audio("https://peshnhsactivator.usere.repl.co/media/pay.mp3");

export default class App extends React.Component {
  constructor(props) {
    super(props);
    // this.state = { name: "Ethan", uid: "yWWkYtegu6OBIFm9hmXZbqJURPo1", check: "" };
    this.state = { name: "", uid: "", check: "", loading: false, scanned: "" };
  }

  playAudio = () => {
    audio.currentTime = 0.0;
    audio.play();
  };

  handleChanges = (e) => {
    this.setState({ [e.target.id]: e.target.value }, () => {});
  };

  handleScan = (value) => {
    if (value == null) return;
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        fetch("https://peshnhsactivator.usere.repl.co/fetchUser?uid=" + value, {
          headers: {
            idtoken: idToken,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              this.setState({
                name: data.name,
                uid: value,
              });
            } else if (data.activated != 1) {
              alert("User not activated!");
            } else {
              alert("No user by that QR code.");
            }
          })
          .catch((error) => {
            alert("Activation error. Try signing out and signing in again.");
            console.log(error);
          });
      })
      .catch(function (error) {
        alert("Activation error. Try signing out and signing in again.");
        console.log(error);
      });
  };

  activate = (e) => {
    this.setState({ loading: true });
    e.preventDefault();
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        fetch(
          "https://peshnhsactivator.usere.repl.co/getCheckedIn?uid=" +
            this.state.uid,
          {
            headers: {
              idtoken: idToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              this.playAudio();
              this.setState({
                name: "",
                uid: "",
                check: "",
                loading: false,
              });
            } else {
              this.setState({ loading: false });
              alert("No user by that QR code.");
            }
          })
          .catch((error) => {
            alert("Activation error. Try signing out and signing in again.");
            console.log(error);
          });
      })
      .catch(function (error) {
        alert("Activation error. Try signing out and signing in again.");
        console.log(error);
      });
  };

  loading = () => {
    return (
      <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4" />
        <h2 className="text-center text-white text-xl font-semibold">
          Loading...
        </h2>
        <p className="w-1/3 text-center text-white">
          Please wait as the user is signed in.
        </p>
      </div>
    );
  };

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.handleScan(this.state.scanned);
    }
  };

  render() {
    return (
      <main
        className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6 h-full"
        tabindex="0"
        x-data
        x-init="$el.focus()"
      >
        {this.state.loading && this.loading()}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">
            CHECK IN (Officers Only)
          </h1>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="py-4">
            <div class="flex flex-col">
              {/* <div class="bg-white shadow sm:rounded-lg mt-4">
                <div class="px-4 py-5 sm:p-6">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Attendance
                  </h3>
                  <div class="mt-2 max-w-xl text-sm leading-5 text-gray-500">
                    <p>Please click "Check In" below.</p>
                  </div>
                  <div class="mt-5 flex items-center">
                    <div class="mt-5 sm:mt-6">
                      <span class="flex w-full rounded-md shadow-sm">
                        <button
                          type="button"
                          class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        >
                          Go back to dashboard
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div> */}

              <label
                for="first_name"
                class="block text-sm font-medium leading-5 text-gray-700"
              >
                Field
              </label>
              <input
                autoFocus
                required
                id="scanned"
                name="scanned"
                value={this.state.scanned}
                onChange={this.handleChanges}
                onKeyDown={this._handleKeyDown}
                class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
              {this.state.name && (
                <div class="bg-white shadow sm:rounded-lg mt-4">
                  <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 mt-4">
                    <svg
                      class="h-6 w-6 text-blue-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                      Check In {this.state.name}?
                    </h3>
                    <div class="mt-2">
                      <p class="text-sm leading-5 text-gray-500 px-4">
                        Check in for the event. MAKE SURE YOU ARE CHECKING IN A
                        PERSON WHO HAS JUST ARRIVED!!
                      </p>
                      <span class="flex w-full rounded-md shadow-sm my-6">
                        <button
                          type="submit"
                          onClick={this.activate}
                          class="inline-flex mx-6 justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        >
                          Check In
                        </button>
                      </span>
                    </div>
                  </div>
                  <div class="mt-5 sm:mt-6 mb-4">
                    <span class="flex w-full rounded-md shadow-sm"></span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    );
  }
}
