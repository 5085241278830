import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  Redirect,
} from "react-router-dom";
import firebase from "firebase/app";
import "firebase/app-check";
// import firebaseAppCheck from "firebase/app-check";
//

import Registration from "./components/reg2025";
import Dashboard from "./components/dashboard";
import Profile from "./components/profile";
import Calendar from "./components/calendar";
import SignIn from "./components/signin";
import CreditLog from "./components/creditlog";
import CreditLogSpring from "./components/creditlogSpring";
import Live from "./components/live";
import Verify from "./components/verify";
import AccountDomain from "./components/accountDomain";
import ActivateCode from "./components/activateCode";
import OfficerScan from "./components/officerScan";
import GetSignedIn from "./components/getSignedIn";
import OfficerCheckIn from "./components/officerCheckIn";
import OfficerCheckInAuto from "./components/officerCheckInAuto";
import OfficerCheckOut from "./components/officerCheckOut";
import CardVerification from "./components/cardverification";

import logo from "./peshlogoSM.png";

/* var firebaseConfig = {
  apiKey: "AIzaSyCWSj7P3rO7aDCrzyb1svIVTJxbka0L_ow",
  authDomain: "portal.peshnhs.org",
  databaseURL: "https://peshnhs-9769a.firebaseio.com",
  projectId: "peshnhs-9769a",
  storageBucket: "peshnhs-9769a.appspot.com",
  messagingSenderId: "393119835864",
  appId: "1:393119835864:web:3c17c4a4585abe2457d9ad",
}; */

const firebaseConfig = {
  apiKey: "AIzaSyC3DOZIo3IjTJdDK7sutkg3Guz-dE_wS1g",
  authDomain: "peshnhs2025.firebaseapp.com",
  projectId: "peshnhs2025",
  storageBucket: "peshnhs2025.appspot.com",
  messagingSenderId: "658898231687",
  appId: "1:658898231687:web:a0c60c12b576de0fb0f322",
  measurementId: "G-SYL2XPB6R3"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// const appCheck = firebase.appCheck();
// // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// // key is the counterpart to the secret key you set in the Firebase console.
// appCheck.activate(
//   // "6LdXhUwcAAAAAGrJo2LgnH_diLLR4u1LPZ2k0G3q",
//   "6LdxIzsnAAAAAJHtsXsb8eD61qzhVKT4eceUcdAB",
//   // Optional argument. If true, the SDK automatically refreshes App Check
//   // tokens as needed.
//   true
// );

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSignedIn: null,
      active: false,
      shouldRedirect: false,
      email: "",
      mobileOpen: false,
    };
  }

  uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // We will display Google as auth providers.
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false,
    },
  };

  // Listen to the Firebase Auth state and set the local state.
  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onIdTokenChanged((user) => {
      if (!!user) {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            console.log(idTokenResult);
            if (!!idTokenResult.claims.active) {
              this.setState({
                isSignedIn: true,
                active: idTokenResult.claims.active,
                email: user.email,
                role: idTokenResult.claims.active == 1 ? 1 : 0,
              });
            } else {
              this.setState({
                isSignedIn: true,
                active: false,
                shouldRedirect: true,
                email: user.email,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.setState({ isSignedIn: !!user });
      }
    });
  }

  renderRoot = () => {
    if (this.state.active == "active") {
      return (
        <>
          <Route exact path="/">
            <Dashboard />
          </Route>
          <Route path="/verify/:name/:code" exact>
            <Verify />
          </Route>
          <Route path="/creditlog">
            <CreditLog />
          </Route>
          <Route path="/creditlogspring">
            <CreditLogSpring />
          </Route>
          <Route path="/live">
            <Live />
          </Route>
          <Route path="/calendar">
            <Calendar />
          </Route>
          <Route path="/profile">
            <Profile />
          </Route>
          <Route path="/eventqr">
            <GetSignedIn />
          </Route>
          {/* {this.state.role == 1 && ( */}
          <Route path="/officerScan">
            <OfficerScan />
          </Route>
          <Route path="/officerCheckIn">
            <OfficerCheckIn />
          </Route>
          <Route path="/officerCheckInAuto">
            <OfficerCheckInAuto />
          </Route>
          <Route path="/officerCheckOut">
            <OfficerCheckOut />
          </Route>
          <Route path="/cardVerification">
            <CardVerification />
          </Route>
          {/* )} */}
        </>
      );
    } else if (this.state.active == "notactive") {
      return (
        <>
          <Route exact path="/">
            <ActivateCode />
          </Route>
          <Route path="/officerScan">
            <OfficerScan />
          </Route>
        </>
      );
    } else {
      return (
        <Route path="/">
          <Registration />
        </Route>
      );
    }
  };

  renderSidebar = () => {
    if (this.state.active == "active") {
      return (
        <>
          {/* <NavLink
            exact
            to="/cardverification"
            className="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-white rounded-md hover:bg-yellow-300 transition ease-in-out duration-150 bg-yellow-500"
          >
            <svg
              className="mr-3 h-6 w-6 text-white-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
              stroke="white"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
              ></path>
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            Cards for Patients
          </NavLink> */}
          <NavLink
            exact
            to="/"
            activeClassName="bg-gray-100"
            className="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
          >
            <svg
              className="mr-3 h-6 w-6 text-gray-500 group-hover:text-gray-500 group-focus:text-gray-600 transition ease-in-out duration-150"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 12l9-9 9 9M5 10v10a1 1 0 001 1h3a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1h3a1 1 0 001-1V10M9 21h6"
              />
            </svg>
            Dashboard
          </NavLink>
          <NavLink
            exact
            to="/creditlog"
            activeClassName="bg-gray-100"
            className="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
          >
            <svg
              className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
              />
            </svg>
            Credit Log (Semester 1)
          </NavLink>
          <NavLink
            exact
            to="/creditlogspring"
            activeClassName="bg-gray-100"
            className="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
          >
            <svg
              className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
              />
            </svg>
            Credit Log (Semester 2)
          </NavLink> 
          {/*<NavLink
            exact
            to="/serviceLog"
            activeClassName="bg-gray-100"
            className="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
          >
            <svg
              className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
              />
            </svg>
            Credit Log (Spring)
          </NavLink>*/}
          {/* <NavLink
            exact
            to="/calendar"
            activeClassName="bg-gray-100"
            className="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
          >
            <svg
              className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            Calendar
          </NavLink> */}
          <NavLink
            exact
            to="/eventqr"
            activeClassName="bg-gray-100"
            className="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
          >
            <svg
              className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"
              />
            </svg>
            Event Check In
          </NavLink>
          <NavLink
            exact
            to="/profile"
            activeClassName="bg-gray-100"
            className="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
          >
            <svg
              className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            Profile
          </NavLink>
        </>
      );
    } else if (this.state.active == "notactive") {
      return (
        <NavLink
          exact
          to="/"
          activeClassName="bg-gray-100"
          className="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
        >
          <svg
            className="mr-3 h-6 w-6 text-gray-500 group-hover:text-gray-500 group-focus:text-gray-600 transition ease-in-out duration-150"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 12l9-9 9 9M5 10v10a1 1 0 001 1h3a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1h3a1 1 0 001-1V10M9 21h6"
            />
          </svg>
          Dashboard
        </NavLink>
      );
    } else {
      return (
        <NavLink
          exact
          to="/"
          activeClassName="bg-gray-100"
          className="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
        >
          <svg
            className="mr-3 h-6 w-6 text-gray-500 group-hover:text-gray-500 group-focus:text-gray-600 transition ease-in-out duration-150"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 12l9-9 9 9M5 10v10a1 1 0 001 1h3a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1h3a1 1 0 001-1V10M9 21h6"
            />
          </svg>
          Registration
        </NavLink>
      );
    }
  };

  closeSidebar = () => {
    this.setState({ mobileOpen: false });
  };

  renderMobileSidebar = () => {
    if (this.state.active == "active") {
      return (
        <>
          <NavLink
            exact
            onClick={this.closeSidebar}
            to="/cardverification"
            activeClassName="bg-red-300 outline-none text-white"
            className="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium text-white rounded-md hover:bg-yellow-300 transition ease-in-out duration-150 bg-yellow-500"
          >
            <svg
              className="mr-3 h-6 w-6 text-white-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
              stroke="white"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
              ></path>
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            Cards for Patients
          </NavLink>
          <NavLink
            exact
            onClick={this.closeSidebar}
            to="/"
            activeClassName="bg-gray-100 outline-none text-gray-900"
            className="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
          >
            <svg
              className="mr-3 h-6 w-6 text-gray-500 group-hover:text-gray-500 group-focus:text-gray-600 transition ease-in-out duration-150"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 12l9-9 9 9M5 10v10a1 1 0 001 1h3a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1h3a1 1 0 001-1V10M9 21h6"
              />
            </svg>
            Dashboard
          </NavLink>
          {/* <NavLink
            exact
            onClick={this.closeSidebar}
            to="/creditlog"
            activeClassName="bg-gray-100 outline-none text-gray-900"
            className="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
          >
                        <svg
              className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
              />
            </svg>
            Credit Log
          </NavLink> */}
        <NavLink
            exact
            onClick={this.closeSidebar}
            to="/creditlog"
            activeClassName="bg-gray-100 outline-none text-gray-900"
            className="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
          >
            <svg
              className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
              ></path>
            </svg>
            Credit Log (Semester 1)
          </NavLink>
          <NavLink
            exact
            onClick={this.closeSidebar}
            to="/creditlogspring"
            activeClassName="bg-gray-100 outline-none text-gray-900"
            className="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
          >
            <svg
              className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
              ></path>
            </svg>
            Credit Log (Semester 2)
          </NavLink>
          {/*<NavLink
            exact
            onClick={this.closeSidebar}
            to="/serviceLog"
            activeClassName="bg-gray-100 outline-none text-gray-900"
            className="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
          >
            <svg
              className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
              ></path>
            </svg>
            Credit Log (Spring)
          </NavLink>*/}
          <NavLink
            exact
            onClick={this.closeSidebar}
            to="/calendar"
            activeClassName="bg-gray-100 outline-none text-gray-900"
            className="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
          >
            <svg
              className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              ></path>
            </svg>
            Calendar
          </NavLink>
          <NavLink
            exact
            onClick={this.closeSidebar}
            to="/eventqr"
            activeClassName="bg-gray-100 outline-none text-gray-900"
            className="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
          >
            <svg
              className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"
              />
            </svg>
            Event Check In
          </NavLink>
          <NavLink
            exact
            onClick={this.closeSidebar}
            to="/profile"
            activeClassName="bg-gray-100 outline-none text-gray-900"
            className="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
          >
            <svg
              className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            Profile
          </NavLink>
        </>
      );
    } else if (this.state.active == "notactive") {
      return (
        <NavLink
          onClick={this.closeSidebar}
          exact
          to="/"
          activeClassName="bg-gray-200"
          className="group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-900 rounded-md bg-gray-100 transition ease-in-out duration-150"
        >
          <svg
            className="mr-3 h-6 w-6 text-gray-500 group-hover:text-gray-500 group-focus:text-gray-600 transition ease-in-out duration-150"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 12l9-9 9 9M5 10v10a1 1 0 001 1h3a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1h3a1 1 0 001-1V10M9 21h6"
            />
          </svg>
          Dashboard
        </NavLink>
      );
    } else {
      return (
        <NavLink
          onClick={this.closeSidebar}
          exact
          to="/"
          activeClassName="bg-gray-200"
          className="group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-900 rounded-md bg-gray-100 transition ease-in-out duration-150"
        >
          <svg
            className="mr-3 h-6 w-6 text-gray-500 group-hover:text-gray-500 group-focus:text-gray-600 transition ease-in-out duration-150"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 12l9-9 9 9M5 10v10a1 1 0 001 1h3a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1h3a1 1 0 001-1V10M9 21h6"
            />
          </svg>
          Registration
        </NavLink>
      );
    }
  };

  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    if (this.state.isSignedIn == null) {
      return (
        <div class="lds-default">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    } else if (this.state.isSignedIn == false) {
      return <SignIn />;
    } else if (this.state.email.indexOf("@mypisd.net") == -1 && this.state.email.indexOf("@pisd.edu") == -1) {
      return <AccountDomain />;
    }
    return (
      <Router>
        <div>
          <div className="h-full md:h-screen flex overflow-hidden bg-gray-100">
            {this.state.mobileOpen && (
              <div x-show="sidebarOpen" className="md:hidden">
                <div
                  x-show="sidebarOpen"
                  className="fixed inset-0 z-30 transition-opacity ease-linear duration-300"
                >
                  <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
                </div>
                <div className="fixed inset-0 flex z-40">
                  <div
                    x-show="sidebarOpen"
                    className="flex-1 flex flex-col max-w-xs w-full bg-white transform ease-in-out duration-300 "
                  >
                    <div className="absolute top-0 right-0 -mr-14 p-1">
                      <button
                        onClick={() => this.setState({ mobileOpen: false })}
                        className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600"
                      >
                        <svg
                          className="h-6 w-6 text-white"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                      <div className="flex-shrink-0 flex items-center px-4">
                        <img className="h-8 w-auto" src={logo} alt="PESHNHS" />
                      </div>
                      <nav className="mt-5 px-2">
                        {this.renderMobileSidebar()}
                      </nav>
                    </div>
                    <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                      <a className="flex-shrink-0 group block focus:outline-none">
                        <div className="flex items-center">
                          <div>
                            <img
                              className="inline-block h-10 w-10 rounded-full"
                              src={firebase.auth().currentUser.photoURL}
                              alt=""
                            />
                          </div>
                          <div className="ml-3">
                            <p className="text-base leading-6 font-medium text-gray-700 group-hover:text-gray-900">
                              {firebase.auth().currentUser.displayName}
                            </p>
                            <a
                              onClick={() => {
                                firebase.auth().signOut();
                              }}
                              className="text-sm leading-5 font-medium text-gray-500 group-hover:text-gray-700 group-focus:underline transition ease-in-out duration-150"
                            >
                              Sign Out
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="flex-shrink-0 w-14"></div>
                </div>
              </div>
            )}

            <div className="hidden md:flex md:flex-shrink-0">
              <div className="flex flex-col w-64 border-r border-gray-200 bg-white">
                <div className="h-0 flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                  <div className="flex items-center flex-shrink-0 px-4">
                    <img className="h-8 w-auto" src={logo} alt="PESH NHS" />
                  </div>
                  <nav className="mt-5 flex-1 px-2 bg-white">
                    {this.renderSidebar()}
                  </nav>
                </div>
                <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                  <a
                    href="#"
                    className="flex-shrink-0 group block focus:outline-none"
                  >
                    <div className="flex items-center">
                      <div>
                        <img
                          className="inline-block h-9 w-9 rounded-full"
                          src={firebase.auth().currentUser.photoURL}
                          alt=""
                          referrerpolicy = "no-referrer"
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm leading-5 font-medium text-gray-700 group-hover:text-gray-900">
                          {firebase.auth().currentUser.displayName}
                        </p>
                        <a
                          onClick={() => {
                            firebase.auth().signOut();
                          }}
                        >
                          <p className="text-xs leading-4 font-medium text-gray-500 group-hover:text-gray-700 group-focus:underline transition ease-in-out duration-150">
                            Sign Out
                          </p>
                        </a>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
              <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
                <button
                  onClick={() => this.setState({ mobileOpen: true })}
                  className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150"
                >
                  <svg
                    className="h-6 w-6"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              </div>

              <Switch>{this.renderRoot()}</Switch>
            </div>
          </div>
        </div>
      </Router>
    );
  }
}
