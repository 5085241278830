import React from "react";
import { Link } from "react-router-dom";
import firebase from "firebase";
import QRCode from "react-qr-code";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { status: 0 };
  }

  refresh = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {})
      .catch(function (error) {
        alert("Activation error. Try signing out and signing in again.");
      });
  };

  componentDidMount = () => {
    
    firebase
      .database()
      .ref("events/wos3/" + firebase.auth().currentUser.email.replace(/\./g, ","))
      .on("value", async (snapshot) => {
        console.log(snapshot.val());
        /**console.log(firebase.auth().currentUser.uid);
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then((idToken) => {
            console.log(idToken);
          });**/
        if (snapshot.val() == null) {
          this.setState({ status: 1 });
        } else if (snapshot.val().timein && !snapshot.val().timeout) {
          this.setState({ status: 2 });
        } else if (snapshot.val().timein && snapshot.val().timeout) {
          this.setState({ status: 3 });
        }
      });
  };

  textSelector = () => {
    if (this.state.status == 1) {
      return "You are not checked in to the event - ask an officer to scan your QR code. If you forgot to sign in, talk to an officer ASAP.";
    } else if (this.state.status == 2) {
      // return "You've been checked in, but need to check out. Ask an officer to sign you out upon your dismissal from the event.";
      return "Welcome to Week of Service Day 3 - enjoy!";
    } else if (this.state.status == 3) {
      return "You are all set! You will receive service hours for this event no later than 1 week from the date of the event.";
    } else if (this.state.status == 0) {
      return "Loading.";
    }
  };

  render() {
    return (
      <main
        className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6 h-full"
        tabindex="0"
        x-data
        x-init="$el.focus()"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">Activation</h1>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="py-4">
            <div class="flex flex-col">
              {/* <div class="bg-white shadow sm:rounded-lg mt-4">
                <div class="px-4 py-5 sm:p-6">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Attendance
                  </h3>
                  <div class="mt-2 max-w-xl text-sm leading-5 text-gray-500">
                    <p>Please click "Check In" below.</p>
                  </div>
                  <div class="mt-5 flex items-center">
                    <div class="mt-5 sm:mt-6">
                      <span class="flex w-full rounded-md shadow-sm">
                        <button
                          type="button"
                          class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        >
                          Go back to dashboard
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div> */}

              <div
                class={
                  this.state.status == 1
                    ? "bg-red-600 rounded-lg"
                    : this.state.status == 2
                    ? "bg-green-600 rounded-lg"
                    : this.state.status == 3
                    ? "bg-indigo-600 rounded-lg"
                    : this.state.status == 0
                    ? "hidden"
                    : null
                }
              >
                <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                  <div class="flex items-center justify-between flex-wrap">
                    <div class="w-0 flex-1 flex items-center">
                      <span
                        class={
                          this.state.status == 1
                            ? "bg-red-800 flex p-2 rounded-lg"
                            : this.state.status == 2
                            ? "bg-green-800 flex p-2 rounded-lg"
                            : this.state.status == 3
                            ? "bg-indigo-800 flex p-2 rounded-lg"
                            : this.state.status == 0
                            ? "hidden"
                            : null
                        }
                      >
                        <svg
                          class="h-6 w-6 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                          />
                        </svg>
                      </span>
                      <p class="ml-3 font-medium text-white">
                        <span class="md:hidden">{this.textSelector()}</span>
                        <span class="hidden md:inline">
                          {this.textSelector()}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="bg-white shadow sm:rounded-lg mt-4">
                <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mt-4">
                  <svg
                    class="h-6 w-6 text-green-600"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Get Checked In
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm leading-5 text-gray-500 px-4">
                      Please show the following QR code to an officer to get
                      service hours for this event.
                    </p>
                    {/* <span class="flex w-full rounded-md shadow-sm">
                      <button
                        type="submit"
                        onClick={this.refresh}
                        class="inline-flex mx-6 justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                      >
                        Refresh
                      </button>
                    </span> */}
                  </div>
                </div>
                <div class="mt-5 sm:mt-6 mb-6 flex justify-center align-center">
                  <span class="flex w-full rounded-md align-center justify-center">
                    <QRCode value={firebase.auth().currentUser.uid} level="Q" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
