import React from "react";
import { Link } from "react-router-dom";
import firebase from "firebase";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <main
        className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6"
        tabindex="0"
        x-data
        x-init="$el.focus()"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">
            PESH NHS Calendar
          </h1>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="py-4">
            <div class="flex flex-col">
              <div class="videoWrapper">
                <iframe
                  class="video"
                  src="https://nostalgic-wiles-c7474e.netlify.app/"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
