import React from "react";
import { Link } from "react-router-dom";
import firebase from "firebase";
import { withRouter } from "react-router";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      name: "",
      loading: true,
    };
  }

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        fetch(
          "https://peshnhsactivator.usere.repl.co/verify?e=" +
            this.props.match.params.name +
            "&c=" +
            this.props.match.params.code,
          {
            headers: {
              idtoken: idToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              this.setState({
                success: true,
                name: data.name,
                loading: false,
              });
            } else {
              this.setState({
                success: false,
                loading: false,
              });
            }
          })
          .catch((error) => {
            alert(
              "Activation error. Try signing out and signing in again." + error
            );
          });
      })
      .catch(function (error) {
        alert(
          "Activation error. Try signing out and signing in again." + error
        );
      });
  };

  loading = () => {
    return (
      <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4" />
        <h2 className="text-center text-white text-xl font-semibold">
          Loading...
        </h2>
        <p className="w-1/3 text-center text-white">
          Verifying scanned QR code
        </p>
      </div>
    );
  };

  render() {
    return (
      <main
        className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6"
        tabindex="0"
        x-data
        x-init="$el.focus()"
      >
        {this.state.loading && this.loading()}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">
            QR Attendance
          </h1>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="py-4">
            <div class="flex flex-col">
              {this.state.success && (
                <div class="bg-white shadow sm:rounded-lg mt-4">
                  <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mt-4">
                    <svg
                      class="h-6 w-6 text-green-600"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                      You're all set!
                    </h3>
                    <div class="mt-2">
                      <p class="text-sm leading-5 text-gray-500 px-4">
                        Attendance has been logged for "{this.state.name}". If
                        there is a credit associated with this event, it has
                        automatically been added to your credit log.
                      </p>
                    </div>
                  </div>

                  <div class="mt-5 sm:mt-6 mb-4">
                    <span class="flex w-full rounded-md shadow-sm"></span>
                  </div>
                </div>
              )}
              {(!this.state.success && !this.state.loading) && (
                <div class="bg-white shadow sm:rounded-lg mt-4">
                  <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100 mt-4">
                    <svg
                      class="w-6 h-6 text-red-600"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </div>

                  <div class="mt-3 text-center sm:mt-5">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                      Wrong QR Code.
                    </h3>
                    <div class="mt-2">
                      <p class="text-sm leading-5 text-gray-500 px-4">
                        The QR code you have scanned is not valid. Please use
                        your camera app and scan the changing QR code displayed.
                      </p>
                    </div>
                  </div>

                  <div class="mt-5 sm:mt-6 mb-4">
                    <span class="flex w-full rounded-md shadow-sm"></span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default withRouter(App);
