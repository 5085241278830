import React from "react";
import { Link } from "react-router-dom";
import firebase from "firebase";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      question: "",
      qModal: false,
      aModal: false,
      attend: false,
      at1: "",
      at2: "",
      speaker: "",
    };
  }
  componentDidMount = () => {
    firebase
      .database()
      .ref("live/" + "orientation" + "/listener")
      .on("value", async (snapshot) => {
        console.log(snapshot.val());
        if (snapshot.val() != null) {
          this.setState({
            active: snapshot.val().active,
            attendOpen: snapshot.val().attendOpen,
          });
        } else {
          this.setState({ active: false, attendOpen: false });
        }
      });

    firebase
      .database()
      .ref("live/" + "orientation" + "/attendees/" + firebase.auth().currentUser.uid)
      .once("value", async (snapshot) => {
        console.log(snapshot.val());
        if (snapshot.val() != null) {
          this.setState({
            at1: snapshot.val().at1,
            at2: snapshot.val().at2,
            speaker: snapshot.val().to,
            attend: true,
          });
        } else {
          // this.selectSpeaker();
        }
      });
  };

  selectSpeaker = () => {
    const speakers = [
      "Mohit Gupta",
      "Cindy Ngo",
      "Aswathi Thomas",
      "Matthew Zhao",
    ];
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        fetch("https://nhseventf20.usere.repl.co/getSpeaker", {
          method: "get",
          headers: {
            idtoken: idToken,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              this.setState({ speaker: data.result });
            } else {
              this.setState({
                speaker: speakers[Math.floor(Math.random() * speakers.length)],
              });
            }
          })
          .catch((error) => {
            alert(
              "Activation error - this computer does not support our servers. Please fill out the Google Form on the Google Classroom to receive attendance credit."
            );
            this.setState({
              speaker: speakers[Math.floor(Math.random() * speakers.length)],
            });
          });
      })
      .catch(function (error) {
        alert(
          "Activation error - this computer does not support our servers. Please fill out the Google Form on the Google Classroom to receive attendance credit."
        );
        this.setState({
          speaker: speakers[Math.floor(Math.random() * speakers.length)],
        });
      });
  };

  submitAttendance = (e) => {
    e.preventDefault();
    let opts = {
      at1: this.state.at1,
      at2: this.state.at2,
      to: this.state.speaker,
    };
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        fetch("https://nhseventf20.usere.repl.co/submitAttendance", {
          method: "post",
          body: JSON.stringify(opts),
          headers: {
            idtoken: idToken,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              this.setState({ aModal: true, attend: true });
            }
          })
          .catch((error) => {
            alert(
              "Activation error - this computer does not support our servers. Please fill out the Google Form on the Google Classroom to receive attendance credit."
            );
          });
      })
      .catch(function (error) {
        alert(
          "Activation error - this computer does not support our servers. Please fill out the Google Form on the Google Classroom to receive attendance credit."
        );
      });
  };

  showAttendance = () => {
    return (
      <div class="bg-white shadow sm:rounded-lg mt-4">
        <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mt-4">
          <svg
            class="h-6 w-6 text-green-600"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>
        <div class="mt-3 text-center sm:mt-5">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Attendance
          </h3>
          <div class="mt-2">
            <p class="text-sm leading-5 text-gray-500 px-4">
              Please fill out the form below to check in to "NHS Guest Speaker
              Event: Kim Penny":
            </p>
          </div>
        </div>
        <form onSubmit={this.submitAttendance}>
          <div class="mx-6 mb-6 mt-3">
            <label
              for="email_address"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              After listening to the guest speaker event, what is one thing you
              learned?
            </label>
            <div class="rounded-md shadow-sm">
              <textarea
                id="at1"
                value={this.state.at1}
                required
                onChange={this.handleChanges}
                rows="3"
                maxlength={this.state.attend ? "0" : null}
                class="form-textarea mt-1 block w-full sm:text-sm sm:leading-5"
                style={
                  this.state.attend
                    ? { maxHeight: 100, pointerEvents: "none" }
                    : { maxHeight: 100 }
                }
                placeholder=""
              ></textarea>
            </div>
          </div>

          <div class="mx-6 mb-6">
            <label
              for="email_address"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Please write a thank you note to the speaker, Kim.
            </label>
            <textarea
              id="at2"
              value={this.state.at2}
              required
              onChange={this.handleChanges}
              rows="3"
              maxlength={this.state.attend ? "0" : null}
              class="form-textarea mt-1 block w-full sm:text-sm sm:leading-5"
              style={
                this.state.attend
                  ? { maxHeight: 100, pointerEvents: "none" }
                  : { maxHeight: 100 }
              }
              placeholder=""
            ></textarea>
          </div>
          <div class="mt-5 sm:mt-6 mb-4">
            {this.state.attend ? (
              <span class="flex w-full rounded-md shadow-sm justify-center">
                <button
                  type="button"
                  disabled
                  class="inline-flex mx-6 justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Attendance has been marked!
                </button>
              </span>
            ) : (
              <span class="flex w-full rounded-md shadow-sm justify-center">
                <button
                  type="submit"
                  class="inline-flex mx-6 justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Check in to event
                </button>
              </span>
            )}
          </div>
        </form>
      </div>
    );
  };

  closeModals = () => {
    this.setState({
      qModal: false,
      aModal: false,
    });
  };

  handleChanges = (e) => {
    this.setState({ [e.target.id]: e.target.value }, () => {});
  };

  submitQuestion = (e) => {
    e.preventDefault();
    firebase
      .database()
      .ref("live/" + "orientation" + "/questions")
      .push({
        author: firebase.auth().currentUser.uid,
        authorFriendly: firebase.auth().currentUser.displayName,
        question: this.state.question,
      })
      .then(() => {
        this.setState({ question: "", qModal: true });
      });
  };

  showQuestion = () => {
    return (
      <>
        {/* <div class="bg-white shadow sm:rounded-lg mt-4 pb-3">
          <div class="mx-auto flex items-center justify-center mt-3">
            <a class={`button is-primary is-rounded`}>
              <span class="p-2 px-4 bg-blue-200 rounded-full text-2xl m-1 text-center">
                👏
              </span>
            </a>
            <a class={`button is-primary is-rounded`}>
              <span class="p-2 px-4 bg-blue-200 rounded-full text-2xl m-1 text-center">
                😂
              </span>
            </a>
            <a class={`button is-primary is-rounded`}>
              <span class="p-2 px-4 bg-blue-200 rounded-full text-2xl m-1 text-center">
                ❤️
              </span>
            </a>
            <a class={`button is-primary is-rounded`}>
              <span class="p-2 px-4 bg-blue-200 rounded-full text-2xl m-1 text-center">
              🎉
              </span>
            </a>
            <a class={`button is-primary is-rounded`}>
              <span class="p-2 px-4 bg-blue-200 rounded-full text-2xl m-1 text-center">
              🎉
              </span>
            </a>
          </div>
        </div> */}
        <div class="bg-white shadow sm:rounded-lg mt-4">
          <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 mt-4">
            <svg
              class="h-6 w-6 text-blue-600"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-5">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Questions
            </h3>
            <div class="mt-2">
              <p class="text-sm leading-5 text-gray-500 px-4">
                Ask questions to our guest speaker below at any time during the
                event.
              </p>
            </div>
          </div>
          <div class="mt-5 sm:mt-6 mb-4">
            <form onSubmit={this.submitQuestion}>
              <div class="mx-6 mb-6">
                <label
                  for="email_address"
                  class="block text-sm font-medium leading-5 text-gray-700"
                >
                  Question
                </label>
                <input
                  id="question"
                  required
                  value={this.state.question}
                  onChange={this.handleChanges}
                  class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
              <span class="flex w-full rounded-md shadow-sm">
                <button
                  type="submit"
                  class="inline-flex mx-6 justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Submit question
                </button>
              </span>
            </form>
          </div>
        </div>
      </>
    );
  };

  showWaiting = () => {
    return (
      <div class="bg-white shadow sm:rounded-lg mt-4">
        <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 mt-4">
          <svg
            class="h-6 w-6 text-blue-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <div class="mt-3 text-center sm:mt-5">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Event has not started
          </h3>
          <div class="mt-2">
            <p class="text-sm leading-5 text-gray-500 px-4">
              The 2021-2022 Orientation will start at 6:00 PM
              CST. Keep this page open, it will automatically refresh when we
              are live!
            </p>
          </div>
        </div>
        <div class="mt-5 sm:mt-6 mb-4">
          <span class="flex w-full rounded-md shadow-sm"></span>
        </div>
      </div>
    );
  };

  renderAModal = () => {
    return (
      <div>
        <div class="fixed bottom-0 top-0 left-0 px-4 py-4 inset-x-0 sm:flex sm:items-center sm:justify-center overflow-y-auto">
          <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <div class="relative bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6">
            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button
                onClick={() => this.closeModals()}
                type="button"
                class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
              >
                <svg
                  class="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="folder-add w-6 h-6 text-blue-600"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 4a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V8a2 2 0 00-2-2h-5L9 4H4zm7 5a1 1 0 00-2 0v1H8a1 1 0 000 2h1v1a1 1 0 002 0v-1h1a1 1 0 000-2h-1V9z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Attendance
                </h3>
                <div class="mt-2">
                  <p class="text-sm leading-5 text-gray-500">
                    Your attendance to this event has been marked.
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <button
                  type="submit"
                  onClick={() => this.closeModals()}
                  class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Close
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderQModal = () => {
    return (
      <div>
        <div class="fixed bottom-0 top-0 left-0 px-4 py-4 inset-x-0 sm:flex sm:items-center sm:justify-center overflow-y-auto">
          <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <div class="relative bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6">
            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button
                onClick={() => this.closeModals()}
                type="button"
                class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
              >
                <svg
                  class="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="folder-add w-6 h-6 text-blue-600"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 4a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V8a2 2 0 00-2-2h-5L9 4H4zm7 5a1 1 0 00-2 0v1H8a1 1 0 000 2h1v1a1 1 0 002 0v-1h1a1 1 0 000-2h-1V9z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Question
                </h3>
                <div class="mt-2">
                  <p class="text-sm leading-5 text-gray-500">
                    Your question was submitted!
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <button
                  type="submit"
                  onClick={() => this.closeModals()}
                  class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Close
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <main
        className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6"
        tabindex="0"
        x-data
        x-init="$el.focus()"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">
            PESH NHS Orientation 2021-2022
          </h1>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="py-4">
            <div class="flex flex-col">
              <div class="videoWrapper">
                <iframe
                  class="video"
                  src="https://www.youtube.com/embed/Qzwzhnhv-Ks?autoplay=1"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; allowfullscreen"
                  allowFullScreen="allowFullScreen"
                ></iframe>
              </div>
              {/* <div class="bg-white shadow sm:rounded-lg mt-4">
                <div class="px-4 py-5 sm:p-6">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Attendance
                  </h3>
                  <div class="mt-2 max-w-xl text-sm leading-5 text-gray-500">
                    <p>Please click "Check In" below.</p>
                  </div>
                  <div class="mt-5 flex items-center">
                    <div class="mt-5 sm:mt-6">
                      <span class="flex w-full rounded-md shadow-sm">
                        <button
                          type="button"
                          class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        >
                          Go back to dashboard
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div> */}
              {this.state.qModal && this.renderQModal()}
              {this.state.aModal && this.renderAModal()}
              {this.state.attendOpen ? this.showAttendance() : null}
              {this.state.active ? this.showQuestion() : this.showWaiting()}
              <div class="bg-white shadow sm:rounded-lg mt-4">
                <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 mt-4">
                  <svg
                    class="h-6 w-6 text-blue-600"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Can't see the stream?
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm leading-5 text-gray-500 px-4 pb-6">
                      Is your YouTube stream showing "restricted"? Make sure to
                      open up the portal in a browser where your PISD account is
                      not logged in, especially if you are using Chrome. PISD
                      restricts the YouTube video. The best way around this is
                      if you use an Incognito Tab if you are using Chrome.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}